import { ModularContentPage } from "features/modular-content";
import { getModularContentPageServerSideProps } from "features/modular-content/server";
import { createServerSidePropsHandler } from "services/server-utils";

export default ModularContentPage;

export const getServerSideProps = createServerSidePropsHandler(
  getModularContentPageServerSideProps,
  { availableToNonMembers: true, isMarketingPage: true }
);
